import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Tokyo4 = () => (
  <Layout>
    <GatsbySeo
      title="Universidad de Tokyo"
      titleTemplate="%s | Eduardo Ceballos"
      description=""
      canonical="https://eduardoceballos.com/tokyo"
      openGraph={{
        url: `https://eduardoceballos.com/tokyo`,
        title: `Universidad de Tokyo`,
        description: ``,
        images: [
          {
            url: `https://eduardoceballos.com/seo/tokyo.png`,
            alt: ``,
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>POR AMOR A LA VIDA</h1>
    <h6>
      <a href="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/index.html">
        Fuente original.
      </a>
    </h6>
    <div className="tokyo-menu-flex">
      <Link to="/tokyo">0. Presentación</Link>
      <Link to="/tokyo/1">1. Entrevista</Link>
      <Link to="/tokyo/2">2. Recitación</Link>
      <Link to="/tokyo/3">3. Equipo de producción</Link>
      <Link to="/tokyo/4">4. Agradecimientos</Link>
    </div>
    <center>
      <img
        src="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/bmp/amorvida02.bmp"
        alt=""
      />
      <h6>Agradecimiento a:</h6>
      <p>
        Los músicos de "Retratos": Juan Botelli (piano), Guillermo Rubelt
        (guitarra), Gloria Pankaeva (violonchelo),Julio Quite Ros (flauta),
        Nelso Leonardo Montero (clarinete y saxo), Oscar Torres(batería),
        Claudio Ledesma (percusión), Daniel Tinte (percusión),Ricardo Da
        Silva(oboe), Jesús Canavirira (bajo), José Castro (guitarra).
      </p>
      <p>Casa de Cultura, Ciuadad de Salta.</p>
    </center>
  </Layout>
)

export default Tokyo4
